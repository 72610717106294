<template>
  <div>
    <div class="e-breadcrumb">会员管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-handle-box" style="margin-bottom: 50px">
            <div class="handle-box">
              <span class="v-form-label">会员昵称:</span>
              <el-input placeholder="会员昵称" size="small" style="width:265px" v-model="nickName" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">会员手机号:</span>
              <el-input placeholder="会员手机号" size="small" style="width:265px" v-model="phone" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">性别:</span>
              <el-select size="small" style="width:176px" v-model="sex" filterable clearable
                         placeholder="请选择会员性别">
                <el-option
                  v-for="item in sexList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <span class="v-form-label">会员状态:</span>
              <el-select size="small" style="width:265px" v-model="status" filterable clearable
                         placeholder="请选择会员性别">
                <el-option
                  v-for="item in statusList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div style="float: left">
                <el-row type="flex" justify="end">
                  <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                  </el-button>
                </el-row>
              </div>

              <div style="float: left">
                <el-row type="flex" justify="end">
                  <el-button size="small" class="e-button" type="primary" :loading="loading" @click="uploadExcel">导入
                  </el-button>
                </el-row>
              </div>
              <div style="float: left">
                <el-row type="flex" justify="end">
                  <el-button size="small" class="e-button" type="primary" @click="downloadMuBan">模板
                  </el-button>
                </el-row>
              </div>
            </div>
          </div>
          <br/>
          <br/>

          <div class="e-table-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th class="th-l" width="260">头像</th>
                <th class="th-l" width="130">昵称</th>
                <th class="th-l" width="130">手机号</th>
                <th class="th-l" width="130">性别</th>
                <th class="th-l" width="130">客户来源</th>
                <th class="th-l" width="200">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l">
                  <img :src="row.autographImg" width="45px" height="45px"/>
                </td>
                <td class="td-l">
                  {{row.memberName}}
                </td>
                <td class="td-l">
                  {{row.phone}}
                </td>
                <td class="td-l">
                  <span v-if="row.sex === '1'">女</span>
                  <span v-if="row.sex === '0'">男</span>
                </td>
                <td class="td-l">
                  <span v-if="row.customersSource === '0'">上门客人</span>
                  <span v-if="row.customersSource === '1'">员工带客</span>
                  <span v-if="row.customersSource === '2'">客带客</span>
                  <span v-if="row.customersSource === '3'">大众点评</span>
                  <span v-if="row.customersSource === '4'">美团</span>
                  <span v-if="row.customersSource === '5'">支付宝</span>
                  <span v-if="row.customersSource === '6'">商家</span>
                </td>
                <td class="td-l" style="width: 200px">
                  <el-button type="text" icon="el-icon-edit" @click="editEvent(row.mbCardList)">修改卡金
                  </el-button>
                  <el-button type="text" icon="el-icon-delete" class="red" @click="deleteEvent(row)">删除
                  </el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 修改卡金 -->
    <el-dialog title="提示" :visible.sync="editVisible" width="300px" center>
      <div class="del-dialog-cnt">
        <div class="demo-input-suffix">
          卡名称：
          <el-select v-model="memberCardId" placeholder="请选择" size="small" style="width: 200px"
                     @change="setCard(memberCardId)">
            <el-option
              v-for="item in mbCardList"
              :key="item.id"
              :label="item.cardName"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="demo-input-suffix" style="margin-top: 8px">
          卡余额：
          <el-input v-model="mcItem.balance" placeholder="请输入内容" size="small" style="width: 200px"></el-input>
        </div>
        <div class="demo-input-suffix" style="margin-top: 8px">
          赠送金：
          <el-input v-model="mcItem.donationAmount" placeholder="请输入内容" size="small" style="width: 200px"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="editVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="updateMemberCard">确 定</el-button>
            </span>
    </el-dialog>
    <!-- 失败提示框 -->
    <el-dialog title="提示" :visible.sync="shibaiVisible" width="300px" center :close-on-click-modal="false">
      <p v-if="cfList.length>0">重复个数:{{cfList.length}}</p>
      <p v-if="cwList.length>0">失败个数:{{cwList.length}}</p>
      <br>
      失败的会员手机号:<span v-for="item in cwList">{{item}},</span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delMember()">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
  import {Base_url} from '../../config/index'

  export default {
    data() {
      return {
        base_url: '',
        loading: false,
        shibaiVisible: false,
        cfList: [],
        cwList: [],
        sex: '',
        id: '',
        nickName: '',
        phone: '',
        status: '',

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        editVisible: false,
        mbCardList: [],
        memberCardId: '',
        mcItem: {},

        enableVisible: false,
        delVisible: false,
        prohibitVisible: false,
        editItem: {},
        sexList: [{
          value: '0',
          label: '男'
        }, {
          value: '1',
          label: '女'
        }],
        statusList: [{
          value: '1',
          label: '禁用'
        }, {
          value: '0',
          label: '启用'
        }],
        delItem:{},
      }
    },
    async created() {
      this.searchEvent();
      this.base_url = Base_url
    },
    methods: {
      deleteEvent(delItem){
        this.delItem=delItem
        this.delVisible=true
      },
      async delMember(){
        let res = await this.$post("/admin/deleteMember", {id:this.delItem.id});
        if (res.code == 200) {
          this.$message.success(res.message)
          this.searchEvent();
          this.delVisible=false
        } else {
          this.$message.error(res.message)
        }
      },
      async uploadExcel() {
        let oFile = document.createElement("input");
        oFile.setAttribute("type", "file");
        oFile.click();
        oFile.onchange = async (e) => {
          event.preventDefault();
          let formData = new FormData();
          formData.append('file', e.target.files[0]);
          this.loading = true
          let res = await this.$fileUpload("/admin/uploadExcel", formData);
          if (res.code == 200) {
            this.cfList = res.cfList
            this.cwList = res.cwlist
            this.searchEvent();
            this.loading = false
            this.shibaiVisible = true
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        }
      },
      async downloadMuBan() {
        location.href = this.base_url + '/admin/excelMuBan';
      },

      setCard(memberCardId) {
        this.mbCardList.forEach(item => {
          if (memberCardId == item.id) {
            this.mcItem = item
          }
        })
      },

      async updateMemberCard() {
        let data = {
          id: this.memberCardId,
          balance:this.mcItem.balance,
          donationAmount: this.mcItem.donationAmount,
        };
        let res = await this.$post("/admin/updateMemberCard", data);
        if (res.code == 200) {
          this.searchEvent();
          this.editVisible = false;
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          sex: this.sex,
          status: this.status,
          memberName: this.nickName,
          phone: this.phone,
          isDel: '0',
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let dataSet = await this.$get("/admin/getMbMemberForPage", data)
        let res = dataSet.dataSet
        let list = res.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },

      editEvent(mbCardList) {
        this.mcItem={}
        this.memberCardId=''
        this.mbCardList = mbCardList
        this.editVisible = true;
      },
    },
  }

</script>

<style scoped>

  .th-l {
    text-align: center;
    padding-right: 10px;

  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .off {
    background-color: #fff;
  }

  .on {
    background-color: #ececea;
  }
</style>
